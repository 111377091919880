import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";

import { staticAPI } from "../../api/v2";
import { CategorizedMetricPath, RequestOptions, StaticData } from "../../types";

import { statics as staticCategory } from "../../utils/const";

import {
	categorizeMetricPath,
	dynamicSort,
	sortAudioFeed,
	sortBrowsers,
	sortComputeUnits,
	sortNetworks,
	sortOperators,
	sortTestDuration,
	sortVideoFeed
} from "../../utils/method";

export interface IStaticsReducer {
	classificators: StaticData;
	errorMessage: AxiosError | null;
	isFetching: boolean;
	categorizedMetricPath: CategorizedMetricPath | {};
}

const initialState: IStaticsReducer = {
	classificators: {
		artifact: [],
		assert_status: [],
		audio_feed: [],
		aws_status: [],
		browser: [],
		compute_unit: [],
		file_type: [],
		increment_strategy: [],
		language: [],
		location: [],
		media_type: [],
		member_role: [],
		metric_path: [],
		network: [],
		node_status: [],
		operator: [],
		payment_plan: [],
		payment_status: [],
		property: [],
		result_status: [],
		run_status: [],
		test_duration: [],
		test_mode: [],
		video_feed: []
	},
	errorMessage: null,
	isFetching: false,
	categorizedMetricPath: {}
};

export const fetchStatics = createAsyncThunk<
	{ response: string[]; staticsType: string },
	{ navigate: NavigateFunction; options: RequestOptions; staticsType: string },
	{ rejectValue: AxiosError }
>(
	"statics/fetchStatics",
	async ({ navigate, options, staticsType }, { rejectWithValue }) => {
		try {
			const response = await staticAPI.read(navigate, options, staticsType);
			return { response: response as string[], staticsType };
		} catch (error) {
			return rejectWithValue(error as AxiosError);
		}
	}
);

export const fetchAllStatics = createAsyncThunk<
	StaticData,
	{ navigate: NavigateFunction; options: RequestOptions },
	{ rejectValue: AxiosError }
>(
	"statics/fetchAllStatics",
	async ({ navigate, options }, { rejectWithValue }) => {
		try {
			const response = await staticAPI.read(navigate, options);
			return response as StaticData;
		} catch (error) {
			return rejectWithValue(error as AxiosError);
		}
	}
);

const staticsSlice = createSlice({
	name: "statics",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchStatics.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchStatics.fulfilled, (state, action) => {
				const { response, staticsType } = action.payload;
				const statics = response
					.map((value: string) => ({
						name: value,
						value
					}))
					.sort(dynamicSort("value"));

				state.classificators[staticsType as keyof StaticData] = statics;
				if (staticsType === staticCategory.METRIC_PATH) {
					state.categorizedMetricPath = categorizeMetricPath(statics);
				}
				state.isFetching = false;
			})
			.addCase(fetchStatics.rejected, (state, action) => {
				state.errorMessage = action.payload || null;
				state.isFetching = false;
			})
			.addCase(fetchAllStatics.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchAllStatics.fulfilled, (state, action) => {
				const payload = action.payload;
				const {
					artifact,
					audio_feed,
					assert_status,
					browser,
					compute_unit,
					file_type,
					increment_strategy,
					language,
					location,
					media_type,
					member_role,
					network,
					node_status,
					operator,
					payment_plan,
					payment_status,
					property,
					result_status,
					run_status,
					test_duration,
					test_mode,
					video_feed
				} = payload;

				const aws_status = payload.aws_status || payload.aws_state;

				state.classificators = {
					...state.classificators,
					artifact: artifact.sort(dynamicSort("value")),
					audio_feed: sortAudioFeed(audio_feed),
					assert_status: assert_status.sort(dynamicSort("value")),
					aws_status: aws_status.sort(dynamicSort("value")),
					browser: sortBrowsers(browser),
					compute_unit: sortComputeUnits(compute_unit),
					file_type: file_type.sort(dynamicSort("value")),
					increment_strategy: increment_strategy.sort(dynamicSort("value")),
					language: language.sort(dynamicSort("value")),
					location: location.sort(dynamicSort("value")),
					media_type: media_type.sort(dynamicSort("value")),
					member_role: member_role.sort(dynamicSort("value")),
					network: sortNetworks(network),
					node_status: node_status.sort(dynamicSort("value")),
					operator: sortOperators(operator),
					payment_plan: payment_plan.sort(dynamicSort("value")),
					payment_status: payment_status.sort(dynamicSort("value")),
					property: property.sort(dynamicSort("value")),
					result_status: result_status.sort(dynamicSort("value")),
					run_status: run_status.sort(dynamicSort("value")),
					test_duration: sortTestDuration(test_duration),
					test_mode: test_mode.sort(dynamicSort("value")),
					video_feed: sortVideoFeed(video_feed)
				};
				state.isFetching = false;
			})
			.addCase(fetchAllStatics.rejected, (state, action) => {
				state.errorMessage = action.payload || null;
				state.isFetching = false;
			});
	}
});

export default staticsSlice.reducer;
